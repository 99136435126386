const XHRRequest = new XMLHttpRequest();
const name = document.getElementById("name")
const nameErr = document.querySelector(".warning")
const email = document.getElementById("email")
const emailErr = document.querySelector(".warning.email")
const message = document.getElementById("message")
const messageErr = document.querySelector(".warning.message")
const captcha = document.getElementById("g-recaptcha-response")
const validatorMessages = Array.from(document.getElementsByClassName('form__message'))


export function checkForm (token) {
    let validName, validEmail, validCaptcha, validMessage = false

    function checkName () {
        if (!name.value || name.value.length < 3 || name.value.length > 30) {
            validName = false
            nameErr.style.display = "block"
        } else {
            validName = name.value
        }
    }
    checkName()

    function checkMessage () {
        if (!message.value || name.value.length < 3) {
            validMessage = false
            messageErr.style.display = 'block'
        } else {
            validMessage = message.value
        }
    }
    checkMessage()

    function checkEmail () {
        if (!email.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            validEmail = false
            emailErr.style.display = 'block'
        } else {
            validEmail = email.value
        }
    }
    checkEmail()

    function checkCaptcha () {
        const captchaStr = token
        if (!captchaStr) {
            validCaptcha = false
        } else {
            validCaptcha = captchaStr
            captcha.setAttribute('value', token)
        }
    }
    checkCaptcha()

    if (validName && validEmail && validCaptcha && validMessage) {
        new Notify({
            status: 'success',
            title: 'Сообщение успешно отправлено!',
            text: 'Спасибо за вашу заявку! В ближайшее время с вами свяжутся.',
            effect: 'fade',
            speed: 300,
            customClass: null,
            customIcon: null,
            showCloseButton: true,
            autoclose: true,
            autotimeout: 3000,
            gap: 20,
            distance: 20,
            type: 1,
            position: 'right top'
        })
        name.value = ''
        email.value = ''
        message.value = ''

        // document.getElementById("submit").setAttribute('disabled', 'disabled')
        // XHRRequest.open('POST', 'https://itomnia.ru/api/send-message', true)
        // XHRRequest.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        // XHRRequest.setRequestHeader('Content-Type', 'application/json')
        // XHRRequest.send(JSON.stringify({
        //     name: validName,
        //     message: validMessage,
        //     phone: validPhone,
        //     email: validEmail,
        //     captcha: validCaptcha
        // }))
        //
        // XHRRequest.onload = function() {
        //     if (XHRRequest.status != 200) { // HTTP ошибка?
        //         // обработаем ошибку
        //         new Notify({
        //             status: 'error',
        //             title: 'Произшла ошибка!',
        //             text: 'Что-то пошло не так, повторите попытку через некоторое время',
        //             effect: 'fade',
        //             speed: 300,
        //             customClass: null,
        //             customIcon: null,
        //             showCloseButton: true,
        //             autoclose: true,
        //             autotimeout: 3000,
        //             gap: 20,
        //             distance: 20,
        //             type: 1,
        //             position: 'right top'
        //         })
        //         document.getElementById("submit").removeAttribute('disabled')
        //         return;
        //     }
        //     name.value = ''
        //     phone.value = ''
        //     email.value = ''
        //     captcha.value = ''
        //     message.value = ''
        //     document.getElementById("submit").removeAttribute('disabled')
        //     new Notify({
        //         status: 'success',
        //         title: 'Сообщение успешно отправлено!',
        //         text: 'Спасибо за вашу заявку! В ближайшее время с вами свяжутся.',
        //         effect: 'fade',
        //         speed: 300,
        //         customClass: null,
        //         customIcon: null,
        //         showCloseButton: true,
        //         autoclose: true,
        //         autotimeout: 3000,
        //         gap: 20,
        //         distance: 20,
        //         type: 1,
        //         position: 'right top'
        //     })
        // };
        //
        // XHRRequest.onerror = function() {
        //     // обработаем ошибку, не связанную с HTTP (например, нет соединения)
        //     document.getElementById("submit").removeAttribute('disabled')
        //     new Notify({
        //         status: 'error',
        //         title: 'Произшла ошибка!',
        //         text: 'Что-то пошло не так, повторите попытку через некоторое время',
        //         effect: 'fade',
        //         speed: 300,
        //         customClass: null,
        //         customIcon: null,
        //         showCloseButton: true,
        //         autoclose: true,
        //         autotimeout: 3000,
        //         gap: 20,
        //         distance: 20,
        //         type: 1,
        //         position: 'right top'
        //     })
        // };
    }
}

Array.from(document.getElementsByTagName('input')).forEach(item => {
    item.addEventListener('change',() => {
        nameErr.style.display = 'none'
        messageErr.style.display = 'none'
        emailErr.style.display = 'none'
    })
})

Array.from(document.getElementsByTagName('textarea')).forEach(item => {
    item.addEventListener('change',() => {
        nameErr.style.display = 'none'
        messageErr.style.display = 'none'
        emailErr.style.display = 'none'
    })
})

// document.getElementById("submit").onclick = checkForm
